import { applyMiddleware, compose, createStore } from "redux";
import reducers from "../reducers/index";
import { routerMiddleware } from "react-router-redux";
import history from "../../shared/history";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/index";

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;
// Tell react-snap how to save Redux state

export default function configureStore(initialState) {
  const store = createStore(
    reducers,
    initialState || preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  window.snapSaveState = () => ({
    __PRELOADED_STATE__: store.getState()
  });

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers/index", () => {
      const nextRootReducer = require("../reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
export { history };
