import React from "react";
import "./Logo.less";

const Logo = ({ customStyles = {} }) => {
  return (
    <div className="logo" style={customStyles}>
      <img src="/assets/images/go-to.png" width={11} alt="" />
      <span>Bit</span>
      <span className="logo-v">V</span>elocity
    </div>
  );
};

export default Logo;
