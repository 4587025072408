import React from "react";
import { hydrate, render } from "react-dom";
import NextApp from "./NextApp";
import { AppContainer } from "react-hot-loader";
import "./app/components/base/FontAwesome";
// import registerServiceWorker from "./registerServiceWorker";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(<NextApp />, rootElement);
} else {
  const renderer = Component => {
    render(
      // Wrap App inside AppContainer
      <AppContainer>
        <Component />
      </AppContainer>,
      document.getElementById("root")
    );
  };
  renderer(NextApp);
  // Webpack Hot Module Replacement API
  if (module.hot) {
    module.hot.accept("./NextApp", () => {
      renderer(NextApp);
    });
  }
}

// registerServiceWorker();
