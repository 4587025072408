import { library, IconName, SizeProp } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";

import {
  faPhone,
  faMobileAlt,
  faEnvelope,
  faPlus,
  faMoneyBillWave,
  faAngleDoubleUp,
  faAngleDoubleDown,
  faUser,
  faExchangeAlt,
  faEnvelopeSquare,
  faBriefcase,
  faEnvelopeOpenText,
  faEnvelopeOpen,
  faLock,
  faWalking,
  faBuilding,
  faInfo,
  faQuestion,
  faFileSignature,
  faArrowLeft,
  faSave,
  faTimes,
  faInbox,
  faArrowRight,
  faRedo,
  faRedoAlt,
  faPoundSign
} from "@fortawesome/free-solid-svg-icons";

export default library.add(
  faFacebookF,
  faTwitter,
  faLinkedin,
  faInstagram,
  faYoutube,
  faPhone,
  faMobileAlt,
  faEnvelope,
  faPlus,
  faMoneyBillWave,
  faAngleDoubleUp,
  faAngleDoubleDown,
  faUser,
  faExchangeAlt,
  faEnvelope,
  faEnvelopeSquare,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faBriefcase,
  faLock,
  faWalking,
  faBuilding,
  faInfo,
  faQuestion,
  faFileSignature,
  faArrowLeft,
  faArrowRight,
  faSave,
  faTimes,
  faRedo,
  faRedoAlt,
  faInbox,
  faPoundSign
);
