export enum SocketActions {
  JOINED,
  LEFT,
  RENAME
}
export enum SocketEvents {
  CONNECT = "connect",
  DISCONNECT = "disconnect",
  RegisterEvent = "register_event",
  UnRegisterEvent = "unregister_event",
  OneTimeEvent = "OneTimeEvent"
}
export enum SocketMessageTypes {
  CHAT = "chat",
  NOTIFICATION = "notification"
}
export enum SocketCustomEvents {
  AwaitingEmailVerification = "AwaitingEmailVerification",
  AwaitingPasswordRecovery = "AwaitingPasswordRecovery",
  ReceivedBalance = "AwaitNewBalance",

  SubscribeToBriefRecentHistory = "SubscribeToBriefRecentHistory",
  SubscribeToTrades = "SubscribeToTrades",
  GetInitialRecentTrades = "GetInitialRecentTrades",
  SubscribeToOrderExecution = "SubscribeToOrderExecution",
  SubscribeToBalanceUpdateInHeader = "SubscribeToBalanceUpdateInHeader"
}
