import * as io from "socket.io-client";
import LoggerService from "./logger";
import { Constants } from "./constants";
import { mdRegisterSocketEvent } from "../models/register-socket-event";
import { SocketCustomEvents, SocketEvents } from "../enums/socket";
import { notification, message } from "antd";

export default class Socket {
  private static _instance: Socket;

  static get Instance(): Socket {
    if (!this._instance) {
      this._instance = new Socket();
    }
    return this._instance;
  }
  private io;
  RegisterEvent = "register_event";
  private prevRegisteredEvents: any[] = [];
  log: LoggerService = LoggerService.getInstance();

  connect(callback?) {
    this.io = io(Constants.Instance.BaseURL, {
      forcenew: false,
      path: Constants.Instance.EndPoints.GetStreamSocket,
      reconnection: true,
      reconnectionDelay: 3000,
      reconnectionAttempts: 20,
      transports: ["polling", "websocket"]
    });

    this.io.on("reconnect_attempt", _ => {
      this.io.io.opts.transports = ["polling", "websocket"];
    });

    this.io.on(SocketEvents.CONNECT, e => {
      this.log.debug("socket connected");
      success();
      // openNotification();
      this.prevRegisteredEvents.forEach(({ event, payload, callback }) => {
        this.emitEvent(event, payload, callback);
      });

      if (typeof callback === "function") {
        callback();
      }
    });

    const success = () => {
      message.success("Socket Connection Established", 2);
    };

    this.io.on(SocketEvents.DISCONNECT, e => {
      this.log.debug("socket disconnected");
      if (typeof callback === "function") {
        callback();
      }
    });
  }

  registerEvent(event: SocketCustomEvents, payload, callback) {
    const filteredEvent = this.prevRegisteredEvents.filter(
      m => m.event === event
    )[0];
    if (filteredEvent) {
      const index = this.prevRegisteredEvents.indexOf(filteredEvent);
      this.prevRegisteredEvents[index] = { event, callback, payload };
    } else {
      this.prevRegisteredEvents.push({ event, callback, payload });
    }

    if (this.io) {
      this.emitEvent(event, payload, callback);
    }
  }

  emitEvent(event: SocketCustomEvents, payload?: any, callback?) {
    this.log.debug("Emitting event:", event);
    if (this.io._callbacks["$" + event]) {
      //Handler not present, install now
      this.io.off(event);
    }
    this.io.on(event, callback);
    this.io.emit(
      SocketEvents.RegisterEvent,
      new mdRegisterSocketEvent(event, payload)
    );
  }

  unregisterEvent(event: SocketCustomEvents) {
    if (this.io) {
      this.io.emit(SocketEvents.UnRegisterEvent, event);
      this.io.off(event);
    }
  }

  emitOneTimeEvent(payload: mdRegisterSocketEvent) {
    if (this.io) {
      this.io.emit(SocketEvents.OneTimeEvent, payload);
    }
  }
}

// const openNotification = () => {
//   notification.success({
//     message: "Socket Connection Established",
//     placement: "bottomLeft",
//     style: {
//       width: 400,
//       marginLeft: 0,
//       bottom: 20
//     },
//     className: "hide-notif"
//   });

//   notification.config({
//     // placement: "bottomLeft",
//     bottom: 50,
//     duration: 3
//   });
// };
