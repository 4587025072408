import React from "react";
import { Route, Switch, Redirect } from "react-router";

import { StaticConstants, Constants } from "../shared/constants";
import { StaticHelper } from "../shared/static-helper";
import asyncComponent from "../app/components/base/AsyncComponent";
import { MainApp } from "../containers/App/MainApp";
import { BaseComponent } from "../app/components/base/BaseComponent";
import ScrollToTop from "../util/ScrollToTop";

import loadable from "@loadable/component";
import { PrerenderedComponent } from "react-prerendered-component";

const prerenderedLoadable = dynamicImport => {
  const LoadableComponent = loadable(dynamicImport);
  return React.memo(props => (
    // you can use the `.preload()` method from react-loadable or react-imported-component`
    <PrerenderedComponent live={LoadableComponent.load()}>
      <LoadableComponent {...props} />
    </PrerenderedComponent>
  ));
};

const Legal = prerenderedLoadable(() =>
  import("../app/components/legal/Legal").then(module => module.default)
);

const STO = prerenderedLoadable(() =>
  import("../app/components/header/sto/STO").then(module => module.default)
);

const Security = prerenderedLoadable(() =>
  import("../app/components/footer/security/Security").then(
    module => module.default
  )
);

const InvestorsZone = prerenderedLoadable(() =>
  import("../app/components/footer/investor-zone/InvestorZone").then(
    module => module.default
  )
);

const Support = prerenderedLoadable(() =>
  import("../app/components/footer/support/Support").then(
    module => module.default
  )
);

const AboutUs = prerenderedLoadable(() =>
  import("../app/components/header/about/About").then(module => module.default)
);

const Fees = prerenderedLoadable(() =>
  import("../app/components/footer/fees/Fees").then(module => module.default)
);

const Faq = prerenderedLoadable(() =>
  import("../app/components/header/faq/Faq").then(module => module.default)
);

const Blockchain = prerenderedLoadable(() =>
  import("../app/components/header/blockchain/Blockchain").then(
    module => module.default
  )
);

const BlockchainAdvisory = prerenderedLoadable(() =>
  import(
    "../app/components/header/blockchain/BlockchainAdvisory/BlockchainAdvisory"
  ).then(module => module.default)
);

const TradeHistoryTable = prerenderedLoadable(() =>
  import("../app/components/home/tradebriefhistory/TradeHistoryTable").then(
    module => module.default
  )
);

const TradingViewComponent = prerenderedLoadable(() =>
  import("../app/modules/trade/TradingViewComponent").then(
    module => module.default
  )
);

const OTC = prerenderedLoadable(() =>
  import("../app/components/header/otc/OTC").then(module => module.default)
);

const ContactUsComponent = prerenderedLoadable(() =>
  import("../app/components/contact-us/ContactUsComponent").then(
    module => module.default
  )
);

const HomeComponent = prerenderedLoadable(() =>
  import("../app/components/home/HomeComponent").then(module => module.default)
);
const AccountVerificationComponent = prerenderedLoadable(() =>
  import("../app/components/account/AccountVerificationComponent").then(
    module => module.default
  )
);
const EmailConfirmationComponent = prerenderedLoadable(() =>
  import("../app/components/account/EmailConfirmationComponent").then(
    module => module.default
  )
);
const LoginComponent = prerenderedLoadable(() =>
  import("../app/components/account/LoginComponent").then(
    module => module.default
  )
);
const SignUpComponent = prerenderedLoadable(() =>
  import("../app/components/account/SignUpComponent").then(
    module => module.default
  )
);
const FundingComponent = prerenderedLoadable(() =>
  import("../app/modules/funding/FundingComponent").then(
    module => module.default
  )
);
const TradeComponent = prerenderedLoadable(() =>
  import("../app/modules/trade/TradeComponent").then(module => module.default)
);
const ForgotPasswordComponent = prerenderedLoadable(() =>
  import("../app/components/account/ForgotPasswordComponent").then(
    module => module.default
  )
);

const PasswordRecoveryComponent = prerenderedLoadable(() =>
  import("../app/components/account/PasswordRecoveryComponent").then(
    module => module.default
  )
);

const MyAccountSwitch = prerenderedLoadable(() =>
  import("../app/components/account/my-account/MyAccountSwitch").then(
    module => module.default
  )
);

const OpenRoutes = props => {
  return (
    <ScrollToTop>
      <Switch>
        <Route
          exact
          path={`${props.match.url}${StaticConstants.RoutePaths.Login}`}
          render={() => <LoginComponent {...props} />}
        />
        <Route
          exact
          path={`${props.match.url}${StaticConstants.RoutePaths.SignUp}`}
          render={() => <SignUpComponent {...props} />}
        />
        <Route
          exact
          path={`${props.match.url}${StaticConstants.RoutePaths.AccountVerify}`}
          render={() => <AccountVerificationComponent {...props} />}
        />
        <Route
          exact
          path={`${props.match.url}${StaticConstants.RoutePaths.EmailConfirmation}`}
          render={() => <EmailConfirmationComponent {...props} />}
        />
        <Route
          exact
          path={`${props.match.url}${StaticConstants.RoutePaths.AccountForgotPassword}`}
          render={() => <ForgotPasswordComponent {...props} />}
        />
        <Route
          exact
          path={`${props.match.url}${StaticConstants.RoutePaths.AccountPasswordRecovery}`}
          render={() => <PasswordRecoveryComponent {...props} />}
        />
        <Route
          exact
          path={`${props.match.url}${StaticConstants.RoutePaths.Home}*`}
          render={() => <MainApp {...props} />}
        />
        {/* <Route path="*" render={() => <MainApp {...props} match={{ url: "/" }} />} /> */}
      </Switch>
    </ScrollToTop>
  );
};

const restrictedRouteRenderer = (Comp, props, url) => {
  if (!props.globals.isLoggedIn && props.globals.loginChecked) {
    let redirectURI = encodeURIComponent(window.location.href);
    let to = StaticHelper.getLink(
      StaticConstants.RoutePaths.Login +
        "?" +
        Constants.Instance.QueryParams.redirectURI +
        "=" +
        redirectURI
    );
    return <Redirect to={to} />;
  }
  return <Comp {...(props as any)} match={{ url: url }} />;
};

class ThemeImplementedRoutes extends BaseComponent {
  render() {
    let blockchainSelector;
    if (this.props) {
      blockchainSelector = this.props.history.location.pathname.replace(
        "/blockchain/",
        ""
      );
    }
    return (
      <div
        className={
          blockchainSelector === "travel" || blockchainSelector === "realEstate"
            ? "gx-main-content-wrapper-card"
            : "gx-main-content-wrapper"
        }
        // style={{
        //   minHeight: 500, // window.innerHeight - this.g.headerHeight,
        //   height: "auto"
        // }}
      >
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}${StaticConstants.RoutePaths.BlockchainAdvisory}`}
            render={() => <BlockchainAdvisory {...(this.props as any)} />}
          />
          <Route
            exact
            path={`${this.props.match.url}${StaticConstants.RoutePaths.STO}`}
            render={() => <STO {...(this.props as any)} />}
          />
          <Route
            exact
            path={`${this.props.match.url}${StaticConstants.RoutePaths.ContactUs}`}
            render={() => <ContactUsComponent {...(this.props as any)} />}
          />
          <Route
            path={`${this.props.match.url}${StaticConstants.RoutePaths.Trade}`}
            render={() => {
              return restrictedRouteRenderer(
                TradeComponent,
                this.props,
                `${this.props.match.url}${StaticConstants.RoutePaths.Trade}`
              );
            }}
          />
          <Route
            path={`${this.props.match.url}${StaticConstants.RoutePaths.Funding}`}
            render={() => {
              return restrictedRouteRenderer(
                FundingComponent,
                this.props,
                `${this.props.match.url}${StaticConstants.RoutePaths.Funding}`
              );
            }}
          />
          <Route
            path={`${this.props.match.url}${this.constants.RoutePaths.MyAccount}`}
            render={() => {
              return restrictedRouteRenderer(
                MyAccountSwitch,
                this.props,
                `${this.props.match.url}${this.constants.RoutePaths.MyAccount}`
              );
            }}
          />
          <Route
            exact
            path={`${this.props.match.url}${StaticConstants.RoutePaths.Travel}`}
            render={() => <Blockchain {...(this.props as any)} />}
          />
          <Route
            exact
            path={`${this.props.match.url}${StaticConstants.RoutePaths.RealEstate}`}
            render={() => <Blockchain {...(this.props as any)} />}
          />
          <Route
            exact
            path={`${this.props.match.url}${StaticConstants.RoutePaths.InvestorsZone}`}
            render={() => <InvestorsZone {...(this.props as any)} />}
          />
          <Route
            exact
            path={`${this.props.match.url}${StaticConstants.RoutePaths.AboutUs}`}
            render={() => <AboutUs {...(this.props as any)} />}
          />
          <Route
            exact
            path={`${this.props.match.url}${StaticConstants.RoutePaths.OurFees}`}
            render={() => <Fees {...(this.props as any)} />}
          />
          <Route
            exact
            path={`${this.props.match.url}${StaticConstants.RoutePaths.Support}`}
            render={() => <Support {...(this.props as any)} />}
          />
          <Route
            exact
            path={`${this.props.match.url}${StaticConstants.RoutePaths.KnowledgeBase}`}
            render={() => <TradeHistoryTable {...(this.props as any)} />}
          />
          <Route
            exact
            path={`${this.props.match.url}${StaticConstants.RoutePaths.Market}`}
            render={() => <TradeHistoryTable {...(this.props as any)} />}
          />
          <Route
            path={`${this.props.match.url}${StaticConstants.RoutePaths.Trading}`}
            render={() => <TradingViewComponent {...(this.props as any)} />}
          />
          <Route
            exact
            path={`${this.props.match.url}${StaticConstants.RoutePaths.FAQ}`}
            render={() => <Faq {...(this.props as any)} />}
          />
          <Route
            exact
            path={`${this.props.match.url}${StaticConstants.RoutePaths.Security}`}
            render={() => <Security {...(this.props as any)} />}
          />
          <Route
            exact
            path={`${this.props.match.url}${StaticConstants.RoutePaths.Terms}`}
            render={() => <Legal {...(this.props as any)} />}
          />
          <Route
            exact
            path={`${this.props.match.url}${StaticConstants.RoutePaths.Privacy}`}
            render={() => <Legal {...(this.props as any)} />}
          />
          <Route
            exact
            path={`${this.props.match.url}${StaticConstants.RoutePaths.OTC}`}
            render={() => <OTC {...(this.props as any)} />}
          />
          <Route
            path={`${this.props.match.url}/`}
            render={() => {
              if (this.g.isLoggedIn) {
                return (
                  <Redirect
                    to={this.getLink(this.constants.RoutePaths.Trade)}
                  />
                );
              }
              return <HomeComponent {...(this.props as any)} />;
            }}
          />
        </Switch>
      </div>
    );
  }
}

export { OpenRoutes, ThemeImplementedRoutes };
